import { useCallback, useEffect, useState } from 'react';

import { decreaseElement, increaseElement, resetBasket } from '~/lib/cart/slice';
import { useAppDispatch, useAppSelector } from '~/lib/hooks';

export const useBasketMemory = () => {
    const dispatch = useAppDispatch();
    const selectedProducts = useAppSelector((state) => state.cart.data);
    const [loading, setLoading] = useState(true);

    const handleResetBasket = useCallback(() => {
        dispatch(resetBasket());
    }, [dispatch]);

    const handleDecreaseCount = useCallback(
        (id: number) => () => {
            dispatch(
                decreaseElement({
                    id,
                }),
            );
        },
        [dispatch],
    );

    const handleIncreaseCount = useCallback(
        (id: number) => () => {
            dispatch(
                increaseElement({
                    id,
                }),
            );
        },
        [dispatch],
    );

    useEffect(() => {
        if (!loading) {
            // Сохранение корзины в sessionStorage при изменении состояния корзины
            sessionStorage.setItem('cart', JSON.stringify(selectedProducts));
        }
    }, [selectedProducts, loading]);

    return {
        loading,
        onReset: handleResetBasket,
        onDecrease: handleDecreaseCount,
        onIncrease: handleIncreaseCount,
        setLoading,
    };
};
