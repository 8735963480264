import React from 'react';
import { PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, Spinner } from '@chakra-ui/react';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import { resetBasket } from '~/lib/cart/slice';
import { useAppDispatch, useAppSelector } from '~/lib/hooks';
import type { City } from '~/lib/user/api';
import { useGetCitiesQuery } from '~/lib/user/api';
import { updateUser } from '~/lib/user/slice';

import styles from './select-city.module.css';

interface SelectCityProps {
    onClose: () => void;
    setIsSelectOtherCity: (value: boolean) => void;
    setSessionCity: (selectedCity: string) => void;
}

export const SelectCity = ({ onClose, setIsSelectOtherCity, setSessionCity }: SelectCityProps) => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetCitiesQuery();

    const user = useAppSelector((state) => state?.user?.data);

    const handleSelectCity = (city: City) => {
        dispatch(updateUser({ address: city }));
        // Сохранение корзины в sessionStorage при изменении состояния корзины
        sessionStorage.setItem('city', JSON.stringify(city));
        setSessionCity(city.name ?? '');
        onClose();
        setIsSelectOtherCity(false);

        if (!user.email) {
            dispatch(resetBasket());
        }
    };

    return (
        <PopoverContent pt='28px' pr='24px' pb='28px' pl='24px' w={'375px'} borderRadius='12px' border={'none'}>
            <PopoverCloseButton color='rgba(207, 207, 207, 1)' />
            <PopoverHeader borderBottomWidth={'none'}>
                <TypographyCore size='l' view='bold'>
                    Выберите город получателя подарка
                </TypographyCore>
            </PopoverHeader>
            <PopoverBody borderBottomWidth={'none'}>
                <TypographyCore view='medium' size='s' color='heavy'>
                    Так мы сможем показать Вам только те бренды, которые присутствуют в выбранном городе
                </TypographyCore>
            </PopoverBody>
            <PopoverFooter borderTopWidth={'none'}>
                {isFetching ? (
                    <Spinner color='rgba(3, 77, 33, 1)' />
                ) : (
                    <>
                        {data?.data.items.map((item) => (
                            <ButtonCore
                                view='ghost'
                                size='small'
                                key={item.id}
                                onClick={() => {
                                    handleSelectCity(item);
                                }}
                                className={styles.button}
                            >
                                {item.name}
                            </ButtonCore>
                        ))}
                    </>
                )}
            </PopoverFooter>
        </PopoverContent>
    );
};
