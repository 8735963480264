import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import isNull from 'lodash.isnull';

import { ButtonCore } from '~/components/core-components';
import { ProfileModal } from '~/components/profile-modal';

import { ProfileMenu } from './profile-menu';

export const ProfileBlock = ({ isCookiePresent, isFetching }: { isCookiePresent: boolean | null; isFetching: boolean }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {isNull(isCookiePresent) || isCookiePresent ? (
                <ProfileMenu isCookiePresent={isCookiePresent} isFetching={isFetching} />
            ) : (
                <>
                    <ButtonCore onClick={onOpen} view='outline' size='large'>
                        Войти
                    </ButtonCore>
                </>
            )}
            {isOpen ? <ProfileModal onClose={onClose} /> : null}
        </>
    );
};
