import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

import { useAppDispatch } from '~/lib/hooks';
import { addNotification } from '~/lib/notification/slice';
import { useLazyGetUserInfoQuery } from '~/lib/user/api';
import { resetUser } from '~/lib/user/slice';
import { updateUser } from '~/lib/user/slice';
import { deleteCookie } from '~/app/actions';

export const useGetUserInformation = (useNotification?: boolean) => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [triggerUserInfo, { data, error, isFetching }] = useLazyGetUserInfoQuery();

    useEffect(() => {
        if (data?.data) {
            dispatch(updateUser(data?.data));
            if (useNotification) {
                dispatch(
                    addNotification({
                        status: 'success',
                        title: 'Успех',
                        description: `Вы успешно вошли в свой личный кабинет`,
                    }),
                );
            }
        }
    }, [data, dispatch, useNotification]);

    useEffect(() => {
        if (error && 'status' in error && error.status === 403) {
            deleteCookie('token');
            dispatch(resetUser());
            router.push('/catalog/');
        }
    }, [dispatch, error, router]);

    return {
        triggerUserInfo,
        isFetching,
    };
};
