import { LuMap } from 'react-icons/lu';
import { useDisclosure } from '@chakra-ui/react';

import { ButtonCore } from '~/components/core-components';
import { MapMobileModal } from '~/components/map-mobile-modal';

export const MapMobileBlock = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <ButtonCore view='secondary' onClick={onOpen} size='large'>
                <LuMap color='rgba(3, 77, 33, 1)' size='24' />
            </ButtonCore>
            {isOpen ? <MapMobileModal onClose={onClose} /> : null}
        </>
    );
};
