import React, { useEffect, useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement, InputProps, InputRightElement } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';

import { ButtonCore } from '~/components/core-components';

import { useAppDispatch, useAppSelector } from '~/lib/hooks';
import { setSearch } from '~/lib/product/slice';

import { MapMobileBlock } from './map-mobile-block';

import styles from './mobile-view.module.css';

export const MobileView = () => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const searchText = useAppSelector((state) => state.product.search);
    const [search, setSearchValue] = useState('');
    const handleInputChange: InputProps['onChange'] = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        dispatch(setSearch(value));
    };

    const handleKeyDownSearch = () => {
        if (search.length > 2) {
            router.push('/catalog/all');
        }
    };

    useEffect(() => {
        setSearchValue(searchText);
    }, [searchText]);

    return (
        <div className={styles['mobile-header']}>
            <InputGroup borderRadius={'8px'} w={'74vw'}>
                <InputLeftElement pointerEvents='none'>
                    <SearchIcon color='gray.300' />
                </InputLeftElement>
                <Input
                    variant='filled'
                    backgroundColor='rgba(244, 244, 244, 1)'
                    placeholder='Искать в Sympee'
                    onChange={handleInputChange}
                    value={search}
                    h='44px'
                    pr={'60px'}
                    _hover={{
                        borderColor: 'rgba(3, 77, 33, 1)',
                    }}
                    _active={{
                        borderColor: 'rgba(3, 77, 33, 1)',
                    }}
                    _focusVisible={{
                        borderColor: 'rgba(3, 77, 33, 1)',
                    }}
                />
                {search ? (
                    <InputRightElement pr='16px'>
                        <ButtonCore view='link' onClick={handleKeyDownSearch} size='small'>
                            Искать
                        </ButtonCore>
                    </InputRightElement>
                ) : null}
            </InputGroup>
            <MapMobileBlock />
        </div>
    );
};
