import { createIcon } from '@chakra-ui/icons';

export const TelegramLogo = createIcon({
    displayName: 'TelegramLogo',
    viewBox: '0 0 48 48',
    path: (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z'
                fill='currentColor'
            />
            <g clipPath='url(#clip0_2486_22265)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M34.3693 13.9066C34.6988 13.7679 35.0595 13.7201 35.4137 13.7681C35.768 13.8161 36.1029 13.9582 36.3836 14.1795C36.6643 14.4009 36.8805 14.6934 37.0098 15.0267C37.1391 15.36 37.1767 15.7219 37.1187 16.0746L34.0946 34.4173C33.8013 36.1866 31.86 37.2013 30.2373 36.32C28.88 35.5826 26.864 34.4466 25.0507 33.2613C24.144 32.668 21.3667 30.768 21.708 29.416C22.0013 28.26 26.668 23.916 29.3347 21.3333C30.3813 20.3186 29.904 19.7333 28.668 20.6666C25.5987 22.984 20.6707 26.508 19.0413 27.5C17.604 28.3746 16.8547 28.524 15.9587 28.3746C14.324 28.1026 12.808 27.6813 11.5707 27.168C9.89865 26.4746 9.97999 24.176 11.5693 23.5066L34.3693 13.9066Z'
                    fill='#4F4F4F'
                />
            </g>
            <defs>
                <clipPath id='clip0_2486_22265'>
                    <rect width='32' height='32' fill='white' transform='translate(8 8)' />
                </clipPath>
            </defs>
        </svg>
    ),
});
