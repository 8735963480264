'use client';
import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';

import { useAppSelector } from '~/lib/hooks';

export const Notification = () => {
    const notification = useAppSelector((state) => state.notification.list);

    const toast = useToast();

    useEffect(() => {
        if (notification.length) {
            notification?.map((item) => toast(item));
        }
    }, [notification, toast]);

    return null;
};
